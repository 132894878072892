import { AxiosResponse } from 'axios';
import { instanceApi } from './instance';

// Array with stages and user data
export const getUserSettingsApi = (): Promise<AxiosResponse> => instanceApi.get('api/client/info');

// Update user data
export const setBasicUserInfo = (payload: any): Promise<void> => instanceApi.post('api/client', payload);

// Update user data
export const updateUser = (payload: any): Promise<void> => instanceApi.patch('api/client/user', payload);

// Get credit bureaus
export const getCreditBureaus = async (): Promise<AxiosResponse> => {
  const list = await instanceApi.get('/api/credit-bureau');
  return list.data;
};

// Send selected credit bureaus
export const sendSelectedCreditBureaus = (bureaus: []): Promise<void> => instanceApi.post('/api/credit-bureau/accept-privacy-policy', {
  creditBureauIds: bureaus,
});

// Update company data
export const updateCompany = (payload: any): Promise<void> => instanceApi.patch('api/client/company', payload);

// Get array of companies
export const getCompaniesList = async (): Promise<void> => instanceApi.get('api/credit-score/companies');

// Send id of selected company
export const sendSelectedCompanyId = (id: string): Promise<void> => instanceApi.get(`api/credit-score/external?companyBureauId=${id}`);

// Get peer companies list
export const getBestCompanyOptions = async (annualRevenue: string, industryId: string): Promise<any> => {
  const { data } = await (instanceApi.get(`api/business-value/peer-companies?revenue=${annualRevenue}&industryId=${industryId}`) as any);
  const findTheBestCompany = data.find((company: any) => company.isTheBestCompany);
  return { isTheBestCompany: findTheBestCompany.id, companiesList: data };
};

// Get peer company details
export const getPeerCompanyDetails = (id: string): Promise<void> => instanceApi.get(`api/business-value/peer-company?peerCompanyId=${id}`);

// Get business value
export const getBusinessValue = (annualRevenue: string, industryId: string): Promise<void> => instanceApi.get(`api/business-value?revenue=${annualRevenue}&industryId=${industryId}`);

// Get user company details
export const comparePeerCompanyId = (id: string): Promise<void> => instanceApi.get(`api/business-value/compare?peerCompanyId=${id}`);

// Get calculated status
export const getDataShareStatuses = (): Promise<void> => instanceApi.get('api/business-value/data-share-statuses');

// Calculate metrics
export const calculateMetrics = (): Promise<void> => instanceApi.get('api/business-value/calculate-metrics');
